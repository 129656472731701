const partyCards = [
  {
    image: require('./assets/girl9.jpg'),
    alt: 'Bachelore Party',
    title: 'BACHELOR PARTY STRIPPERS',
    textOne: 'SOMETHING FOR EVERYONE',
    textTwo: 'Need a stripper for your next bachelor party? Look no further than Exotic Dollz where we provide the sexiest strippers for bachelor parties. We know that getting married is a big step in your life, and we want to help ring in the chapter by celebrating with you. Congratulations and enjoy the best strippers that Exotic Dollz has to offer! Upgrade your party package to include a Lesbian Toy Show called "2 Girl Lesbian Show." End the evening with sensual private lap dances and let these hotties blow your mind with their skills.',
    button: 'Book Now',
    className: 'bachelor-party',
    link: 'https://form.jotform.com/221912691200042'
  },
  {
    image: require('./assets/girl8.jpg'),
    alt: 'Topless waitress',
    title: 'TOPLESS WAITRESS',
    textOne: 'COCKTAILS WITH A VIEW',
    textTwo: 'Liven up your next party by booking a Topless Waitress. You can customize your girls however you want. Want them to wear a bikini or lingerie? No problem! Want them to wear panties below and pasties above? You got it! Looking for a mini-skirt below and topless above? Sure thing! Want them FULLY NUDE? We are the only agency that can do it! Our topless waitresses can meet and greet, help prepare food and drinks, and serve them to your guests. Want to turn up the heat? Our girls can also turn the room into a strip club and offer lap dances, body shots, body rubs, play adult games, and even do erotic girl-on-girl shows! Book today and lets make your party unforgettable!',
    button: 'Book Now',
    className: 'topless-waitress',
    link: 'https://form.jotform.com/221912691200042'
  },
  {
    image: require('./assets/yacht-bikini.jpg'),
    alt: 'Woman on a yacht',
    title: 'BOAT YACHT POOL PARTY',
    textOne: 'CELEBRITY STATUS',
    textTwo: 'Our hot party strippers are ready to get wet and WILD! Our exotic play girls will make anyone wish they were a part of the party. This is something that no one will want to miss out on!',
    button: 'Book Now',
    link: 'https://form.jotform.com/221912691200042',
    className: 'boat-yacht'
  },
  {
    image: require('./assets/girl1.jpg'),
    alt: 'Virtual Girls',
    title: 'VIRTUAL DOLLZ',
    textOne: 'FOR YOUR PRIVACY',
    textTwo: "Our Virtual Dollz are perfect for what you're looking for. We have many open minded girls who can indulge in many various fetishes and fantasies that you might be interested in. Our Dollz can be booked online for a live show or you can request them to create a custom fantasy video just for you.",
    button: 'Book Now',
    link: 'https://form.jotform.com/221912691200042',
    className: 'virtual'
  },
  {
    image: require('./assets/birthdaytwo.jpg'),
    alt: 'Birthday Suprise',
    title: 'BIRTHDAY PARTY',
    textOne: 'MAKE A WISH',
    textTwo: 'You made it another year! Congratulations! Celebrate another year with a sexy ladies legs wrapped around your head. Make this a birthday to remember. Make this the year that you really treat yourself to all of your desires!',
    button: 'Book Now',
    link: 'https://form.jotform.com/221912691200042',
    className: 'birthday'
  },
  {
    image: require('./assets/girl7.jpg'),
    alt: 'Holiday Girl',
    title: 'HOLIDAYS',
    textOne: 'TIS THE SEASON',
    textTwo: 'Holidays can be boring if you dont have any plans. This holiday season include Exotic Dollz in your plans! Our dancers are ready to celebrate any holiday with you at a moments notice! Let us dress up in any kind of outfit that strikes your mood.',
    button: 'Book Now',
    link: 'https://form.jotform.com/221912691200042',
    className: 'holiday'
  }
]


export default partyCards;